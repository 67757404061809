import { toEstimate, shippingDateSelectionReplace } from "../Shared/SiteCommon";

namespace AnshinyaSiteCommon {
    var siteId = 2;

    jQuery(function () {
        replaceForm();


        setupEstimateButton();

        searchReplace();

        shippingDateSelectionReplace();
    });

    function searchReplace() {
        jQuery("form[action='//www.anshinya.com/product-list']").submit(function () {
            event.preventDefault();
            var sk = jQuery("input[name=keyword]").val();
            var url1 = "//api.workup-system.com/Api/SearchKey/ReplaceSearchKey?key=" + encodeURIComponent(sk);
            jQuery.ajax({
                type: "GET",
                url: url1,
                dataType: "json",
                async: false,
            }).then(function (src) {
                window.location.href = "https://www.anshinya.com/product-list?keyword=" + encodeURIComponent(src);
            });
            var url = "//api.workup-system.com/api/SearchKey/RegSearchKey?siteInfoId=2&key=" + encodeURIComponent(sk);
            jQuery.get(url);
        });
    }

    function replaceForm() {
        var alist = jQuery("a[href^='https://www.anshinya.com/contact']");
        alist.attr("href", '/page/34?form=top');

        var forms = jQuery("form[action^='https://www.anshinya.com/contact']");
        forms.attr("action", '/page/34');

        var contactInput = jQuery("input[value='お問い合わせ']");
        contactInput.attr("onClick", 'jumpContact()');
    }


    function jumpContact() {
        location.replace('/page/34?from=product')
    }


    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate(siteId);
            });
        }
    } 

    //function toEstimate() {
    //    const formInnnerHtml = jQuery("#register").html();
    //    const results = new Array();
    //    let idx = 0;
    //    jQuery(".cart_data_box")
    //        .each(function () {
    //            results.push(parseProduct(idx, this));
    //            idx++;
    //        });

    //    const query = results.join("&");
    //    const url = "https://www.workup-system.com/Estimate/Print/?siteId=2&" + query;
    //    window.open(url);
    //}

    //function parseProduct(idx, itemDiv) {
    //    const productName = jQuery(itemDiv).find(".goods_name").text();
    //    const unitPrice = jQuery(itemDiv).find(".cart_price").find(".price").text().replace("円", "").replace(",", "");
    //    const selectNum = jQuery(itemDiv).find(".cart_quantity").val();
    //    const qentity = "[" + idx + "].";
    //    return qentity + "productName=" + encodeURIComponent(productName) + "&" + qentity + "unitPrice=" + unitPrice + "&" + qentity + "selectNum=" + selectNum;
    //}

} 